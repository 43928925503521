<template>
  <div class="mobileScene">
     <mHead :name="'双开场景面板'" :add="'/mobile/ProScene'" :adds="'/mobile/ProSceneParam'" :color="true" />
    <div class="banner">
      <div class="bannerBox">
        <div class="title">双开场景面板</div>
        <div class="text">用途广泛，可定制</div>
        <div class="text texts">专用协议，传输稳定</div>
        <div class="text texts">美观大方，多种颜色可选</div>
        <div class="text texts">专用频率，无线抗干扰能力强</div>
      </div>
    </div>
    <div class="advantages">
      <div class="adv">
        <div class="advTit">易使用</div>
        <div class="advText">四个按键</div>
        <div class="advText advText">四种场景</div>
      </div>
      <div class="adv">
        <div class="advTit">按键灯</div>
        <div class="advText">在夜晚</div>
        <div class="advText advText">为你指明方向</div>
      </div>
      <div class="adv">
        <div class="advTit">可定制</div>
        <div class="advText">定制文字</div>
        <div class="advText advText">定制场景</div>
      </div>
    </div>
    <div class="sec1">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img src="../../assets/mobile/product/scene1.png" class="img" />
          <div class="name">黑色</div>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/mobile/product/scene2.png" class="img" />
          <div class="name">绅士灰</div>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/mobile/product/scene3.png" class="img" />
          <div class="name">香槟金</div>
        </van-swipe-item>
      </van-swipe>
      <div class="sec1Box">
        <div class="tit">多种颜色，多种风格</div>
        <div class="text">适应你的装修风格，个性化定制用户体验</div>
      </div>
    </div>
    <div class="sec2"></div>
  </div>
</template>

<script>
import mHead from "../../components/MobileHead.vue";
export default {
  components: {
    mHead
  }
};
</script>
<style scoped lang="less">
.banner {
  width: 100%;
  height: 8rem;
  background-image: url(../../assets/mobile/product/sceneBanner.png);
  background-size: 100% 100%;
  padding-top: 1.2rem;
  .bannerBox {
    width: 4rem;
    height: 3.2rem;
    padding-left: 0.3rem;
    background-color: rgba(255, 144, 0, 0.5);
    border-radius: 0px 0px 0.4rem 0px;
    font-size: 0.48rem;
    line-height: 0.48rem;
    font-weight: bold;
    color: #ffffff;
    text-align: left;
    .title {
      padding-top: 0.53rem;
    }
    .text {
      margin-top: 0.24rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
    }
    .texts {
      margin-top: 0.16rem;
    }
  }
}
.advantages {
  width: 100%;
  background-color: #fff;
  padding: 0 0.8rem;
  padding-top: 0.32rem;
  padding-bottom: 0.3rem;
  font-size: 0.24rem;
  line-height: 0.24rem;
  display: flex;
  justify-content: space-between;
  .advTit {
    font-weight: bold;
    color: #ff9000;
  }
  .advText {
    font-weight: 300;
    color: #333333;
    margin-top: 0.16rem;
  }
}
.sec1 {
  width: 100%;
  height: 7.38rem;
  color: #fff;
  position: relative;
  text-align: center;
  /deep/.van-swipe__indicator {
     width: 0.16rem;
      height: 0.16rem;
      margin-right: 0.16rem;
      background-color: #999;
      opacity: 1;
      &:last-of-type{
        margin-right: 0;
      }
    // &:nth-of-type(2) {
    //   background-color: #6d6d6d;
    // }
    // &:nth-of-type(3) {
    //   background-color: #b8b093;
    //   margin-right: 0;
    // }
  }

  // /deep/ .van-swipe__indicator--active {
  //   border: 0.04rem solid #ff9000 !important;
  // }
  .my-swipe {
    width: 100%;
    height: 100%;
    .van-swipe-item {
      width: 100%;
      height: 100%;

      .img {
        width: 100%;
        height: 100%;
      }
      .name {
        width: 100%;
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: 400;
        color: #333333;
        position: absolute;
        bottom: 1.36rem;
      }
    }
  }
  .sec1Box {
    width: 100%;
    position: absolute;
    top: 0.8rem;
    left: 0px;
    text-align: center;
    z-index: 222;
    .tit {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333333;
    }
    .text {
      margin-top: 0.38rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
    }
  }
}
.sec2 {
  width: 100%;
  height: 9rem;
  background-image: url(../../assets/mobile/product/scene4.png);
  background-size: 100% 100%;
}

</style>